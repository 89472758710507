::selection {
  background: $black;
  color: #fff;
}

.container-image {
  background-size: cover;
  background-position: center;
  background-repeat: repeat-x;
  height: 350px;
  margin: 5rem 0 5rem 0 !important;
}

.container-image-nm {
  @extend .container-image;
  margin: 0 !important;
}

@include media-breakpoint-up(lg) {
  .container-image-nm {
    @extend .container-image;
    margin: 0 !important;
    height: 750px;
  }
}

.container-image-vw {
  @extend .container-image;
  margin: 0 !important;
  height: 100px !important;
}

section {
  @extend .my-5;
}

.zoom {
    transition: all 0.3s;
    overflow: hidden;

    &:hover {
        transform: scale(1.15);}
}

.navbar-brand {
  font-size: 45px;

  @include media-breakpoint-up(sm) {
    font-size: 55px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 60px;
  }
}