.lead, .subtext {
  font-family: $headings-font-family;
}

.serif {
  font-family: $headings-font-family;
}

.subtext {
  @extend .text-uppercase;
}

.subtext-small {
    @extend .subtext;
    font-size: 0.6rem !important;
    font-weight: 300;
}

a {
  color: $black;
}

.btn-arrow {
  @extend .link-offset-2;
  @extend .link-offset-3-hover ;
  @extend .link-underline-opacity-0;
  @extend .link-underline-opacity-75-hover;
  @extend .link-underline-dark;

  &:after {
    content: '→';
    padding-left: 0.5rem;
  }
}

.btn-arrow-down {
    @extend .link-offset-2;
    @extend .link-offset-3-hover ;
    @extend .link-underline-opacity-0;
    @extend .link-underline-opacity-75-hover;
    @extend .link-underline-dark;

    &:after {
        content: '↓';
        padding-left: 0.5rem;
    }
}

strong {
  font-weight: inherit;
}