#cc-main {
  background: transparent;
  color: var(--cc-primary-color);
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.15;
  position: relative;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  position: fixed;
  z-index: var(--cc-z-index)
}

#cc-main :after,
#cc-main :before,
#cc-main a,
#cc-main button,
#cc-main div,
#cc-main h2,
#cc-main input,
#cc-main p,
#cc-main span {
  all: unset;
  box-sizing: border-box
}

#cc-main .pm__badge,
#cc-main button {
  all: initial;
  box-sizing: border-box;
  color: unset;
  visibility: unset
}

#cc-main .pm__badge,
#cc-main a,
#cc-main button,
#cc-main input {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  font-family: inherit;
  font-size: 100%;
  line-height: normal;
  margin: 0;
  outline: revert;
  outline-offset: 2px;
  overflow: hidden
}

#cc-main table,
#cc-main tbody,
#cc-main td,
#cc-main th,
#cc-main thead,
#cc-main tr {
  all: revert;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit
}

:root {
  --cc-modal-border-radius: .5rem;
  --cc-btn-border-radius: 0rem;
  --cc-modal-transition-duration: .25s;
  --cc-modal-margin: 1rem;
  --cc-z-index: 2147483647;
  --cc-bg: #fff;
  --cc-primary-color: #2c2f31;
  --cc-secondary-color: #5e6266;
  --cc-btn-primary-bg: #212529;
  --cc-btn-primary-color: #fff;
  --cc-btn-primary-border-color: var(--cc-btn-primary-bg);
  --cc-btn-primary-hover-bg: #000;
  --cc-btn-primary-hover-color: #fff;
  --cc-btn-primary-hover-border-color: var(--cc-btn-primary-hover-bg);
  --cc-btn-secondary-bg: #fff;
  --cc-btn-secondary-color: var(--cc-primary-color);
  --cc-btn-secondary-border-color: var(--cc-btn-primary-border-color);
  --cc-btn-secondary-hover-bg: #d4dae0;
  --cc-btn-secondary-hover-color: #000;
  --cc-btn-secondary-hover-border-color: #d4dae0;
  --cc-separator-border-color: #f3f3f3;
  --cc-toggle-on-bg: var(--cc-btn-primary-bg);
  --cc-toggle-off-bg: #667481;
  --cc-toggle-on-knob-bg: #fff;
  --cc-toggle-off-knob-bg: var(--cc-toggle-on-knob-bg);
  --cc-toggle-enabled-icon-color: var(--cc-bg);
  --cc-toggle-disabled-icon-color: var(--cc-bg);
  --cc-toggle-readonly-bg: #d5dee2;
  --cc-toggle-readonly-knob-bg: #fff;
  --cc-toggle-readonly-knob-icon-color: var(--cc-toggle-readonly-bg);
  --cc-section-category-border: var(--cc-cookie-category-block-bg);
  --cc-cookie-category-block-bg: #f3f3f3;
  --cc-cookie-category-block-border: #f3f3f3;
  --cc-cookie-category-block-hover-bg: #efefef;
  --cc-cookie-category-block-hover-border: #efefef;
  --cc-cookie-category-expanded-block-bg: transparent;
  --cc-cookie-category-expanded-block-hover-bg: #dee4e9;
  --cc-overlay-bg: rgba(0, 0, 0, .65);
  --cc-webkit-scrollbar-bg: var(--cc-section-category-border);
  --cc-webkit-scrollbar-hover-bg: var(--cc-btn-primary-hover-bg);
  --cc-footer-bg: var(--cc-btn-secondary-bg);
  --cc-footer-color: var(--cc-secondary-color);
  --cc-footer-border-color: #e4eaed;
  --cc-pm-toggle-border-radius: 4em
}

#cc-main.cc--rtl {
  direction: rtl
}

#cc-main .cm__title,
#cc-main a,
#cc-main b,
#cc-main em,
#cc-main strong {
  font-weight: 600
}

#cc-main button>span {
  pointer-events: none
}

#cc-main .cc__link,
#cc-main a {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 0 1px;
  font-weight: 600;
  position: relative;
  transition: background-size .25s, color .25s ease
}

#cc-main .cc__link:hover,
#cc-main a:hover {
  background-size: 100% 1px;
  color: var(--cc-primary-color)
}

#cc-main .cm__desc,
#cc-main .pm__body {
  overscroll-behavior: auto contain;
  scrollbar-width: thin
}

@media screen and (min-width:640px) {
  #cc-main ::-webkit-scrollbar,
  #cc-main ::-webkit-scrollbar-thumb,
  #cc-main ::-webkit-scrollbar-track {
    all: revert
  }
  #cc-main ::-webkit-scrollbar-thumb {
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background: var(--cc-toggle-readonly-bg);
    border: .25rem solid var(--cc-bg);
    border-radius: 1rem
  }
  #cc-main ::-webkit-scrollbar-thumb:hover {
    background: var(--cc-toggle-off-bg)
  }
  #cc-main ::-webkit-scrollbar {
    background: transparent;
    width: 12px
  }
}

html.disable--interaction.show--consent,
html.disable--interaction.show--consent body {
  height: auto !important;
  overflow: hidden !important
}

@media (prefers-reduced-motion) {
  #cc-main {
    --cc-modal-transition-duration: 0s
  }
}

#cc-main .cm {
  background: var(--cc-bg);
  border-radius: var(--cc-modal-border-radius);
  box-shadow: 0 .625em 1.875em rgba(0, 0, 2, .3);
  display: flex;
  flex-direction: column;
  max-width: 24rem;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  transform: translateY(1.6em);
  visibility: hidden;
  z-index: 1
}

#cc-main .cm--top {
  top: var(--cc-modal-margin)
}

#cc-main .cm--middle {
  top: 50%;
  transform: translateY(calc(-50% + 1.6em))
}

#cc-main .cm--bottom {
  bottom: var(--cc-modal-margin)
}

#cc-main .cm--center {
  left: var(--cc-modal-margin);
  margin: 0 auto;
  right: var(--cc-modal-margin);
  width: unset
}

#cc-main .cm--left {
  left: var(--cc-modal-margin);
  margin-right: var(--cc-modal-margin)
}

#cc-main .cm--right {
  margin-left: var(--cc-modal-margin);
  right: var(--cc-modal-margin)
}

#cc-main .cm__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative
}

#cc-main .cm__btns,
#cc-main .cm__links {
  padding: 1rem 1.3rem;
  width: unset
}

#cc-main .cm__texts {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0 0
}

#cc-main .cm__desc,
#cc-main .cm__title {
  padding: 0 1.3rem
}

#cc-main .cm__title {
  font-family: $headings-font-family;
  @extend .fw-bold;
  @extend .fs-3
}

#cc-main .cm__title+.cm__desc {
  margin-top: 1.1em
}

#cc-main .cm__desc {
  color: var(--cc-secondary-color);
  font-size: .9em;
  line-height: 1.5;
  max-height: 40vh;
  overflow-x: visible;
  overflow-y: auto;
  padding-bottom: 1em
}

#cc-main .cm__btns {
  border-top: 1px solid var(--cc-separator-border-color);
  display: flex;
  flex-direction: column;
  justify-content: center
}

#cc-main .cm__btn-group {
  display: grid;
  grid-auto-columns: minmax(0, 1fr)
}

#cc-main .cm__btn+.cm__btn,
#cc-main .cm__btn-group+.cm__btn-group {
  margin-top: .375rem
}

#cc-main .cm--flip .cm__btn+.cm__btn,
#cc-main .cm--flip .cm__btn-group+.cm__btn-group {
  margin-bottom: .375rem;
  margin-top: 0
}

#cc-main .cm--inline .cm__btn+.cm__btn {
  margin-left: .375rem;
  margin-top: 0
}

#cc-main .cm--inline.cm--flip .cm__btn+.cm__btn {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: .375rem
}

#cc-main .cm--inline.cm--flip .cm__btn-group+.cm__btn-group {
  margin-bottom: .375rem;
  margin-right: 0
}

#cc-main .cm--wide .cm__btn+.cm__btn,
#cc-main .cm--wide .cm__btn-group+.cm__btn-group {
  margin-left: .375rem;
  margin-top: 0
}

#cc-main .cm--wide.cm--flip .cm__btn+.cm__btn,
#cc-main .cm--wide.cm--flip .cm__btn-group+.cm__btn-group {
  margin-bottom: 0;
  margin-right: .375rem
}

#cc-main .cm--bar:not(.cm--inline) .cm__btn-group--uneven,
#cc-main .cm--wide .cm__btn-group--uneven {
  display: flex;
  flex: 1;
  justify-content: space-between
}

#cc-main .cm--bar:not(.cm--inline).cm--flip .cm__btn-group--uneven,
#cc-main .cm--wide.cm--flip .cm__btn-group--uneven {
  flex-direction: row-reverse
}

#cc-main .cm__btn {
  background: var(--cc-btn-primary-bg);
  border: 1px solid var(--cc-btn-primary-border-color);
  border-radius: var(--cc-btn-border-radius);
  color: var(--cc-btn-primary-color);
  font-size: .82em;
  font-weight: 600;
  min-height: 42px;
  padding: .5em 1em;
  text-align: center
}

#cc-main .cm__btn:hover {
  background: var(--cc-btn-primary-hover-bg);
  border-color: var(--cc-btn-primary-hover-border-color);
  color: var(--cc-btn-primary-hover-color)
}

#cc-main .cm__btn--secondary {
  background: var(--cc-btn-secondary-bg);
  border-color: var(--cc-btn-secondary-border-color);
  color: var(--cc-btn-secondary-color)
}

#cc-main .cm__btn--secondary:hover {
  background: var(--cc-btn-secondary-hover-bg);
  border-color: var(--cc-btn-secondary-hover-border-color);
  color: var(--cc-btn-secondary-hover-color)
}

#cc-main .cm__btn--close {
  border-radius: 0;
  border-bottom-left-radius: var(--cc-btn-border-radius);
  border-right: none;
  border-top: none;
  display: none;
  font-size: 1em;
  height: 42px;
  min-width: auto !important;
  overflow: hidden;
  padding: 0 !important;
  position: absolute;
  right: 0;
  top: 0;
  width: 42px
}

#cc-main .cm__btn--close svg {
  stroke: var(--cc-btn-primary-color);
  transform: scale(.5);
  transition: stroke .15s ease
}

#cc-main .cm__btn--close:hover svg {
  stroke: var(--cc-btn-primary-hover-color)
}

#cc-main .cm__btn--close.cm__btn--secondary svg {
  stroke: var(--cc-btn-secondary-color)
}

#cc-main .cm__btn--close.cm__btn--secondary:hover svg {
  stroke: var(--cc-btn-secondary-hover-color)
}

#cc-main .cm__btn--close+.cm__texts .cm__title {
  padding-right: 3rem
}

#cc-main .cm--inline .cm__btn-group {
  grid-auto-flow: column
}

#cc-main .cm__footer {
  background: var(--cc-footer-bg);
  border-top: 1px solid var(--cc-footer-border-color);
  color: var(--cc-footer-color);
  padding: .4em 0 .5em
}

#cc-main .cm__links {
  display: flex;
  flex-direction: row;
  padding-bottom: 0;
  padding-top: 0
}

#cc-main .cm__link-group {
  display: flex;
  flex-direction: row;
  font-size: .8em;
  width: 100%
}

#cc-main .cm__link-group>*+* {
  margin-left: 1.3rem
}

#cc-main .cm--flip .cm__btn:last-child {
  grid-row: 1
}

#cc-main .cm--inline.cm--flip .cm__btn:last-child {
  grid-column: 1
}

#cc-main .cm--box .cm__btn--close {
  display: block
}

#cc-main .cm--box.cm--flip .cm__btns {
  flex-direction: column-reverse
}

#cc-main .cm--box.cm--wide {
  max-width: 36em
}

#cc-main .cm--box.cm--wide .cm__btns {
  flex-direction: row;
  justify-content: space-between
}

#cc-main .cm--box.cm--wide .cm__btn-group {
  grid-auto-flow: column
}

#cc-main .cm--box.cm--wide .cm__btn {
  min-width: 120px;
  padding-left: 1.8em;
  padding-right: 1.8em
}

#cc-main .cm--box.cm--wide.cm--flip .cm__btns {
  flex-direction: row-reverse
}

#cc-main .cm--box.cm--wide.cm--flip .cm__btn:last-child {
  grid-column: 1
}

#cc-main .cm--cloud {
  max-width: 54em;
  width: unset
}

#cc-main .cm--cloud .cm__body {
  flex-direction: row
}

#cc-main .cm--cloud .cm__texts {
  flex: 1
}

#cc-main .cm--cloud .cm__desc {
  max-height: 9.4em
}

#cc-main .cm--cloud .cm__btns {
  border-left: 1px solid var(--cc-separator-border-color);
  border-top: none;
  max-width: 23em
}

#cc-main .cm--cloud .cm__btn-group {
  flex-direction: column
}

#cc-main .cm--cloud .cm__btn {
  min-width: 19em
}

#cc-main .cm--cloud.cm--flip .cm__btn-group,
#cc-main .cm--cloud.cm--flip .cm__btns {
  flex-direction: column-reverse
}

#cc-main .cm--cloud.cm--inline .cm__btn-group {
  flex-direction: row
}

#cc-main .cm--cloud.cm--inline .cm__btn {
  min-width: 10em
}

#cc-main .cm--cloud.cm--inline.cm--flip .cm__btn-group {
  flex-direction: row-reverse
}

#cc-main .cm--bar {
  border-radius: 0;
  left: 0;
  margin: 0;
  max-width: unset;
  opacity: 1;
  right: 0;
  transform: translateY(0);
  width: 100vw;
  --cc-modal-transition-duration: .35s
}

#cc-main .cm--bar.cm--top {
  top: 0;
  transform: translateY(-100%)
}

#cc-main .cm--bar.cm--bottom {
  bottom: 0;
  transform: translateY(100%)
}

#cc-main .cm--bar .cm__body,
#cc-main .cm--bar .cm__links {
  margin: 0 auto;
  max-width: 55em;
  width: 100%
}

#cc-main .cm--bar .cm__body {
  padding: .5em 0 .9em
}

#cc-main .cm--bar .cm__btns {
  border-top: none;
  flex-direction: row;
  justify-content: space-between
}

#cc-main .cm--bar .cm__btn-group {
  grid-auto-flow: column
}

#cc-main .cm--bar:not(.cm--inline) .cm__btn+.cm__btn,
#cc-main .cm--bar:not(.cm--inline) .cm__btn-group+.cm__btn-group {
  margin-left: .375rem;
  margin-top: 0
}

#cc-main .cm--bar .cm__btn {
  min-width: 120px;
  padding-left: 2em;
  padding-right: 2em
}

#cc-main .cm--bar.cm--flip:not(.cm--inline) .cm__btn+.cm__btn,
#cc-main .cm--bar.cm--flip:not(.cm--inline) .cm__btn-group+.cm__btn-group {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: .375rem
}

#cc-main .cm--bar.cm--flip .cm__btns {
  flex-direction: row-reverse
}

#cc-main .cm--bar.cm--flip .cm__btn:last-child {
  grid-column: 1
}

#cc-main .cm--bar.cm--inline .cm__body,
#cc-main .cm--bar.cm--inline .cm__links {
  max-width: 74em
}

#cc-main .cm--bar.cm--inline .cm__body {
  flex-direction: row;
  padding: 0
}

#cc-main .cm--bar.cm--inline .cm__btns {
  flex-direction: column;
  justify-content: center;
  max-width: 23em
}

#cc-main .cm--bar.cm--inline.cm--flip .cm__btns {
  flex-direction: column-reverse
}

#cc-main .cc--anim .cm,
#cc-main .cc--anim.cm-wrapper:before {
  transition: opacity var(--cc-modal-transition-duration) ease, visibility var(--cc-modal-transition-duration) ease, transform var(--cc-modal-transition-duration) ease
}

#cc-main .cc--anim .cm__btn,
#cc-main .cc--anim .cm__close {
  transition: background-color .15s ease, border-color .15s ease, color .15s ease
}

.disable--interaction #cc-main .cm-wrapper:before {
  background: var(--cc-overlay-bg);
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  z-index: 0
}

.show--consent #cc-main .cc--anim .cm {
  opacity: 1;
  transform: translateY(0);
  visibility: visible !important
}

.show--consent #cc-main .cc--anim .cm--middle {
  transform: translateY(-50%)
}

.show--consent #cc-main .cc--anim .cm--bar {
  transform: translateY(0)
}

.show--consent #cc-main .cc--anim.cm-wrapper:before {
  opacity: 1;
  visibility: visible
}

#cc-main.cc--rtl .cm__btn--close {
  border-bottom-left-radius: unset;
  border-bottom-right-radius: var(--cc-btn-border-radius);
  left: 0;
  right: unset
}

#cc-main.cc--rtl .cm__btn--close+.cm__texts .cm__title {
  padding-left: 3rem !important;
  padding-right: 1.3rem
}

#cc-main.cc--rtl .cm--inline .cm__btn+.cm__btn {
  margin-left: 0;
  margin-right: .375rem
}

#cc-main.cc--rtl .cm--inline.cm--flip .cm__btn+.cm__btn {
  margin-left: .375rem;
  margin-right: 0
}

#cc-main.cc--rtl .cm:not(.cm--inline).cm--bar .cm__btn+.cm__btn,
#cc-main.cc--rtl .cm:not(.cm--inline).cm--bar .cm__btn-group+.cm__btn-group,
#cc-main.cc--rtl .cm:not(.cm--inline).cm--wide .cm__btn+.cm__btn,
#cc-main.cc--rtl .cm:not(.cm--inline).cm--wide .cm__btn-group+.cm__btn-group {
  margin-left: 0;
  margin-right: .375rem
}

#cc-main.cc--rtl .cm:not(.cm--inline).cm--bar.cm--flip .cm__btn+.cm__btn,
#cc-main.cc--rtl .cm:not(.cm--inline).cm--wide.cm--flip .cm__btn+.cm__btn {
  margin-left: .375rem;
  margin-right: 0
}

#cc-main.cc--rtl .cm__link-group>*+* {
  margin-left: 0;
  margin-right: 1.3rem
}

@media screen and (max-width:640px) {
  #cc-main {
    --cc-modal-margin: .5em
  }
  #cc-main .cm {
    max-width: none !important;
    width: auto !important
  }
  #cc-main .cm__body {
    flex-direction: column !important;
    padding: 0 !important
  }
  #cc-main .cm__btns,
  #cc-main .cm__desc,
  #cc-main .cm__links,
  #cc-main .cm__title {
    padding-left: 1.1rem !important;
    padding-right: 1.1rem !important
  }
  #cc-main .cm__btns {
    border-left: none !important;
    border-top: 1px solid var(--cc-separator-border-color) !important;
    flex-direction: column !important;
    max-width: none !important;
    min-width: auto !important
  }
  #cc-main .cm__btn+.cm__btn,
  #cc-main .cm__btn-group+.cm__btn-group {
    margin: .375rem 0 0 !important
  }
  #cc-main .cm--flip .cm__btn+.cm__btn,
  #cc-main .cm--flip .cm__btn-group+.cm__btn-group {
    margin-bottom: .375rem !important;
    margin-top: 0 !important
  }
  #cc-main .cm__btn-group {
    display: flex !important;
    flex-direction: column !important;
    min-width: auto !important
  }
  #cc-main .cm__btn {
    flex: auto !important
  }
  #cc-main .cm__link-group {
    justify-content: center !important
  }
  #cc-main .cm--flip .cm__btn-group,
  #cc-main .cm--flip .cm__btns {
    flex-direction: column-reverse !important
  }
}

#cc-main .pm-wrapper {
  position: relative;
  z-index: 2
}

#cc-main .pm {
  background: var(--cc-bg);
  border-radius: var(--cc-modal-border-radius);
  box-shadow: 0 .625em 1.875em rgba(0, 0, 2, .3);
  display: flex;
  flex-direction: column;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  visibility: hidden;
  width: 100%;
  width: unset;
  z-index: 1
}

#cc-main svg {
  fill: none;
  width: 100%
}

#cc-main .pm__body,
#cc-main .pm__footer,
#cc-main .pm__header {
  padding: 1em 1.4em
}

#cc-main .pm__header {
  align-items: center;
  border-bottom: 1px solid var(--cc-separator-border-color);
  display: flex;
  justify-content: space-between
}

#cc-main .pm__title {
  align-items: center;
  display: flex;
  flex: 1;
  font-weight: 600;
  margin-right: 2em
}

#cc-main .pm__close-btn {
  background: var(--cc-btn-secondary-bg);
  border: 1px solid var(--cc-btn-secondary-border-color);
  border-radius: var(--cc-btn-border-radius);
  height: 40px;
  position: relative;
  transition: all .15s ease;
  width: 40px
}

#cc-main .pm__close-btn span {
  display: flex;
  height: 100%;
  width: 100%
}

#cc-main .pm__close-btn svg {
  stroke: var(--cc-btn-secondary-color);
  transform: scale(.5);
  transition: stroke .15s ease
}

#cc-main .pm__close-btn:hover {
  background: var(--cc-btn-secondary-hover-bg);
  border-color: var(--cc-btn-secondary-hover-border-color)
}

#cc-main .pm__close-btn:hover svg {
  stroke: var(--cc-btn-secondary-hover-color)
}

#cc-main .pm__body {
  flex: 1;
  overflow-y: auto;
  overflow-y: overlay
}

#cc-main .pm__section,
#cc-main .pm__section--toggle {
  border-radius: var(--cc-btn-border-radius);
  display: flex;
  flex-direction: column;
  margin-bottom: .5em
}

#cc-main .pm__section--toggle .pm__section-desc-wrapper {
  border: 1px solid var(--cc-cookie-category-block-border);
  border-radius: var(--cc-btn-border-radius);
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: none;
  margin-top: 0 !important;
  overflow: hidden
}

#cc-main .pm__section {
  border: 1px solid var(--cc-separator-border-color);
  padding: 1em;
  transition: background-color .25s ease, border-color .25s ease
}

#cc-main .pm__section:first-child {
  border: none;
  margin-bottom: 2em;
  margin-top: 0;
  padding: 0;
  transition: none
}

#cc-main .pm__section:not(:first-child):hover {
  background: var(--cc-cookie-category-block-bg);
  border-color: var(--cc-cookie-category-block-border)
}

#cc-main .pm__section-toggles+.pm__section {
  margin-top: 2em
}

#cc-main .pm__section--toggle {
  background: var(--cc-cookie-category-block-bg);
  border-top: none;
  margin-bottom: .375rem
}

#cc-main .pm__section--toggle .pm__section-title {
  align-items: center;
  background: var(--cc-cookie-category-block-bg);
  border: 1px solid var(--cc-cookie-category-block-border);
  display: flex;
  justify-content: space-between;
  min-height: 58px;
  padding: 1.1em 5.4em 1.1em 1.2em;
  position: relative;
  transition: background-color .25s ease, border-color .25s ease;
  width: 100%
}

#cc-main .pm__section--toggle .pm__section-title:hover {
  background: var(--cc-cookie-category-block-hover-bg);
  border-color: var(--cc-cookie-category-block-hover-border)
}

#cc-main .pm__section--toggle .pm__section-desc {
  margin-top: 0;
  padding: 1em
}

#cc-main .pm__section--toggle.is-expanded {
  --cc-cookie-category-block-bg: var(--cc-cookie-category-expanded-block-bg);
  --cc-cookie-category-block-border: var(--cc-cookie-category-expanded-block-hover-bg)
}

#cc-main .pm__section--toggle.is-expanded .pm__section-title {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0
}

#cc-main .pm__section--toggle.is-expanded .pm__section-arrow svg {
  transform: scale(.5) rotate(180deg)
}

#cc-main .pm__section--toggle.is-expanded .pm__section-desc-wrapper {
  display: flex
}

#cc-main .pm__section--expandable .pm__section-title {
  cursor: pointer;
  padding-left: 3.4em
}

#cc-main .pm__section--expandable .pm__section-arrow {
  background: var(--cc-toggle-readonly-bg);
  border-radius: 100%;
  display: flex;
  height: 20px;
  justify-content: center;
  left: 18px;
  pointer-events: none;
  position: absolute;
  width: 20px
}

#cc-main .pm__section--expandable .pm__section-arrow svg {
  stroke: var(--cc-btn-secondary-color);
  transform: scale(.5)
}

#cc-main .pm__section-title-wrapper {
  align-items: center;
  display: flex;
  position: relative
}

#cc-main .pm__section-title-wrapper+.pm__section-desc-wrapper {
  margin-top: .85em
}

#cc-main .pm__section-title {
  border-radius: var(--cc-btn-border-radius);
  font-size: .95em;
  font-weight: 600
}

#cc-main .pm__badge {
  align-items: center;
  background: var(--cc-btn-secondary-bg);
  border-radius: 5em;
  color: var(--cc-secondary-color);
  display: flex;
  flex: none;
  font-size: .8em;
  font-weight: 600;
  height: 23px;
  justify-content: center;
  margin-left: 1em;
  min-width: 23px;
  overflow: hidden;
  padding: 0 .6em 1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: auto
}

#cc-main .pm__service-counter {
  background: var(--cc-btn-primary-bg);
  color: var(--cc-btn-primary-color);
  padding: 0;
  width: 23px
}

#cc-main .pm__service-counter[data-counterlabel] {
  padding: 0 .6em 1px;
  width: auto
}

#cc-main .section__toggle,
#cc-main .section__toggle-wrapper,
#cc-main .toggle__icon,
#cc-main .toggle__label {
  border-radius: var(--cc-pm-toggle-border-radius);
  height: 23px;
  transform: translateZ(0);
  width: 50px
}

#cc-main .section__toggle-wrapper {
  cursor: pointer;
  position: absolute;
  right: 18px;
  z-index: 1
}

#cc-main .toggle-service {
  height: 19px;
  position: relative;
  right: 0;
  width: 42px
}

#cc-main .toggle-service .section__toggle,
#cc-main .toggle-service .toggle__icon,
#cc-main .toggle-service .toggle__label {
  height: 19px;
  width: 42px
}

#cc-main .toggle-service .toggle__icon {
  position: relative
}

#cc-main .toggle-service .toggle__icon-circle {
  height: 19px;
  width: 19px
}

#cc-main .toggle-service .section__toggle:checked~.toggle__icon .toggle__icon-circle {
  transform: translateX(23px)
}

#cc-main .pm__section--toggle:nth-child(2) .section__toggle-wrapper:after {
  display: none !important
}

#cc-main .section__toggle {
  border: 0;
  cursor: pointer;
  display: block;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0
}

#cc-main .section__toggle:disabled {
  cursor: not-allowed
}

#cc-main .toggle__icon {
  background: var(--cc-toggle-off-bg);
  box-shadow: 0 0 0 1px var(--cc-toggle-off-bg);
  display: flex;
  flex-direction: row;
  pointer-events: none;
  position: absolute;
  transition: all .25s ease
}

#cc-main .toggle__icon-circle {
  background: var(--cc-toggle-off-knob-bg);
  border: none;
  border-radius: var(--cc-pm-toggle-border-radius);
  box-shadow: 0 1px 2px rgba(24, 32, 3, .36);
  display: block;
  height: 23px;
  left: 0;
  position: absolute;
  top: 0;
  transition: transform .25s ease, background-color .25s ease;
  width: 23px
}

#cc-main .toggle__icon-off,
#cc-main .toggle__icon-on {
  height: 100%;
  position: absolute;
  transition: opacity .15s ease;
  width: 100%
}

#cc-main .toggle__icon-on {
  opacity: 0;
  transform: rotate(45deg)
}

#cc-main .toggle__icon-on svg {
  stroke: var(--cc-toggle-on-bg);
  transform: scale(.55) rotate(-45deg)
}

#cc-main .toggle__icon-off {
  opacity: 1
}

#cc-main .toggle__icon-off svg {
  stroke: var(--cc-toggle-off-bg);
  transform: scale(.55)
}

#cc-main .section__toggle:checked~.toggle__icon {
  background: var(--cc-toggle-on-bg);
  box-shadow: 0 0 0 1px var(--cc-toggle-on-bg)
}

#cc-main .section__toggle:checked~.toggle__icon .toggle__icon-circle {
  background-color: var(--cc-toggle-on-knob-bg);
  transform: translateX(27px)
}

#cc-main .section__toggle:checked~.toggle__icon .toggle__icon-on {
  opacity: 1
}

#cc-main .section__toggle:checked~.toggle__icon .toggle__icon-off {
  opacity: 0
}

#cc-main .section__toggle:checked:disabled~.toggle__icon {
  background: var(--cc-toggle-readonly-bg);
  box-shadow: 0 0 0 1px var(--cc-toggle-readonly-bg)
}

#cc-main .section__toggle:checked:disabled~.toggle__icon .toggle__icon-circle {
  background: var(--cc-toggle-readonly-knob-bg);
  box-shadow: none
}

#cc-main .section__toggle:checked:disabled~.toggle__icon svg {
  stroke: var(--cc-toggle-readonly-knob-icon-color)
}

#cc-main .toggle__label {
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: -1
}

#cc-main .pm__section-desc-wrapper {
  color: var(--cc-secondary-color);
  display: flex;
  flex-direction: column;
  font-size: .9em
}

#cc-main .pm__section-desc-wrapper>:not(:last-child) {
  border-bottom: 1px solid var(--cc-cookie-category-block-border)
}

#cc-main .pm__section-services {
  display: flex;
  flex-direction: column
}

#cc-main .pm__service {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: .4em 1.2em;
  position: relative;
  transition: background-color .15s ease
}

#cc-main .pm__service:hover {
  background-color: var(--cc-cookie-category-block-hover-bg)
}

#cc-main .pm__service-header {
  align-items: center;
  display: flex;
  margin-right: 1em;
  width: 100%
}

#cc-main .pm__service-icon {
  border: 2px solid;
  border-radius: 100%;
  height: 8px;
  margin-left: 6px;
  margin-right: 20px;
  margin-top: 1px;
  min-width: 8px
}

#cc-main .pm__service-title {
  font-size: .95em;
  width: 100%;
  word-break: break-word
}

#cc-main .pm__section-desc {
  line-height: 1.5em
}

#cc-main .pm__section-table {
  border-collapse: collapse;
  font-size: .9em;
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-align: left;
  width: 100%
}

#cc-main .pm__table-caption {
  text-align: left
}

#cc-main .pm__table-caption,
#cc-main .pm__table-head>tr {
  border-bottom: 1px dashed var(--cc-separator-border-color);
  color: var(--cc-primary-color);
  font-weight: 600
}

#cc-main .pm__table-tr {
  transition: background-color .15s ease
}

#cc-main .pm__table-tr:hover {
  background: var(--cc-cookie-category-block-hover-bg)
}

#cc-main .pm__table-caption,
#cc-main .pm__table-td,
#cc-main .pm__table-th {
  padding: .625em .625em .625em 1.2em;
  vertical-align: top
}

#cc-main .pm__footer {
  border-top: 1px solid var(--cc-separator-border-color);
  display: flex;
  justify-content: space-between
}

#cc-main .pm__btn-group {
  display: flex
}

#cc-main .pm__btn+.pm__btn,
#cc-main .pm__btn-group+.pm__btn-group {
  margin-left: .375rem
}

#cc-main .pm--flip .pm__btn+.pm__btn,
#cc-main .pm--flip .pm__btn-group+.pm__btn-group {
  margin-left: 0;
  margin-right: .375rem
}

#cc-main .pm__btn {
  background: var(--cc-btn-primary-bg);
  border: 1px solid var(--cc-btn-primary-border-color);
  border-radius: var(--cc-btn-border-radius);
  color: var(--cc-btn-primary-color);
  flex: auto;
  font-size: .82em;
  font-weight: 600;
  min-height: 42px;
  min-width: 110px;
  padding: .5em 1.5em;
  text-align: center;
  transition: background-color .15s ease, border-color .15s ease, color .15s ease
}

#cc-main .pm__btn:hover {
  background: var(--cc-btn-primary-hover-bg);
  border-color: var(--cc-btn-primary-hover-border-color);
  color: var(--cc-btn-primary-hover-color)
}

#cc-main .pm__btn--secondary {
  background: var(--cc-btn-secondary-bg);
  border-color: var(--cc-btn-secondary-border-color);
  color: var(--cc-btn-secondary-color)
}

#cc-main .pm__btn--secondary:hover {
  background: var(--cc-btn-secondary-hover-bg);
  border-color: var(--cc-btn-secondary-hover-border-color);
  color: var(--cc-btn-secondary-hover-color)
}

#cc-main .pm--box {
  height: calc(100% - 2em);
  left: var(--cc-modal-margin);
  margin: 0 auto;
  max-height: 37.5em;
  max-width: 43em;
  right: var(--cc-modal-margin);
  top: 50%;
  transform: translateY(calc(-50% + 1.6em))
}

#cc-main .pm--box.pm--flip .pm__btn-group,
#cc-main .pm--box.pm--flip .pm__footer {
  flex-direction: row-reverse
}

#cc-main .pm--bar {
  border-radius: 0;
  bottom: 0;
  height: 100%;
  margin: 0;
  max-height: none;
  max-width: 29em;
  opacity: 1;
  top: 0;
  width: 100%;
  --cc-modal-transition-duration: .35s
}

#cc-main .pm--bar .pm__section-table,
#cc-main .pm--bar .pm__table-body,
#cc-main .pm--bar .pm__table-td,
#cc-main .pm--bar .pm__table-th,
#cc-main .pm--bar .pm__table-tr {
  display: block
}

#cc-main .pm--bar .pm__table-head {
  display: none
}

#cc-main .pm--bar .pm__table-caption {
  display: block
}

#cc-main .pm--bar .pm__table-tr:not(:last-child) {
  border-bottom: 1px solid var(--cc-separator-border-color)
}

#cc-main .pm--bar .pm__table-td {
  display: flex;
  justify-content: space-between
}

#cc-main .pm--bar .pm__table-td:before {
  color: var(--cc-primary-color);
  content: attr(data-column);
  flex: 1;
  font-weight: 600;
  min-width: 100px;
  overflow: hidden;
  padding-right: 2em;
  text-overflow: ellipsis
}

#cc-main .pm--bar .pm__table-td>div {
  flex: 3
}

#cc-main .pm--bar:not(.pm--wide) .pm__body,
#cc-main .pm--bar:not(.pm--wide) .pm__footer,
#cc-main .pm--bar:not(.pm--wide) .pm__header {
  padding: 1em 1.3em
}

#cc-main .pm--bar:not(.pm--wide) .pm__btn-group,
#cc-main .pm--bar:not(.pm--wide) .pm__footer {
  flex-direction: column
}

#cc-main .pm--bar:not(.pm--wide) .pm__btn+.pm__btn,
#cc-main .pm--bar:not(.pm--wide) .pm__btn-group+.pm__btn-group {
  margin: .375rem 0 0
}

#cc-main .pm--bar:not(.pm--wide).pm--flip .pm__btn-group,
#cc-main .pm--bar:not(.pm--wide).pm--flip .pm__footer {
  flex-direction: column-reverse
}

#cc-main .pm--bar:not(.pm--wide).pm--flip .pm__btn+.pm__btn,
#cc-main .pm--bar:not(.pm--wide).pm--flip .pm__btn-group+.pm__btn-group {
  margin-bottom: .375rem;
  margin-top: 0
}

#cc-main .pm--bar:not(.pm--wide) .pm__badge {
  display: none
}

#cc-main .pm--bar.pm--left {
  left: 0;
  transform: translateX(-100%)
}

#cc-main .pm--bar.pm--right {
  right: 0;
  transform: translateX(100%)
}

#cc-main .pm--bar.pm--wide {
  max-width: 35em
}

#cc-main .pm--bar.pm--wide .pm__body,
#cc-main .pm--bar.pm--wide .pm__footer,
#cc-main .pm--bar.pm--wide .pm__header {
  padding: 1em 1.4em
}

#cc-main .pm--bar.pm--wide.pm--flip .pm__btn-group,
#cc-main .pm--bar.pm--wide.pm--flip .pm__footer {
  flex-direction: row-reverse
}

#cc-main .pm-overlay {
  background: var(--cc-overlay-bg);
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  z-index: 1
}

#cc-main .cc--anim .pm,
#cc-main .cc--anim .pm-overlay {
  transition: opacity var(--cc-modal-transition-duration) ease, visibility var(--cc-modal-transition-duration) ease, transform var(--cc-modal-transition-duration) ease
}

.show--preferences #cc-main .cc--anim .pm {
  opacity: 1;
  visibility: visible !important
}

.show--preferences #cc-main .cc--anim .pm--box {
  transform: translateY(-50%)
}

.show--preferences #cc-main .cc--anim .pm--bar {
  transform: translateX(0)
}

.show--preferences #cc-main .cc--anim .pm-overlay {
  opacity: 1;
  visibility: visible
}

#cc-main.cc--rtl .pm__service-header {
  margin-left: 1em;
  margin-right: 0
}

#cc-main.cc--rtl .pm__section-arrow {
  left: unset;
  right: 18px
}

#cc-main.cc--rtl .section__toggle-wrapper {
  left: 18px;
  right: unset;
  transform-origin: left
}

#cc-main.cc--rtl .toggle-service {
  left: 0
}

#cc-main.cc--rtl .pm__service-icon {
  margin-left: 20px;
  margin-right: 5px
}

#cc-main.cc--rtl .pm__section--toggle .pm__section-title {
  padding-left: 5.4em;
  padding-right: 1.2em
}

#cc-main.cc--rtl .pm__section--expandable .pm__section-title {
  padding-right: 3.4em
}

#cc-main.cc--rtl .pm__badge {
  margin-left: unset;
  margin-right: 1em
}

#cc-main.cc--rtl .toggle__icon-circle {
  transform: translateX(27px)
}

#cc-main.cc--rtl .toggle-service .toggle__icon-circle {
  transform: translateX(23px)
}

#cc-main.cc--rtl .section__toggle:checked~.toggle__icon .toggle__icon-circle {
  transform: translateX(0)
}

#cc-main.cc--rtl .pm__table-td,
#cc-main.cc--rtl .pm__table-th {
  padding-left: unset;
  padding-right: 1.2em;
  text-align: right
}

#cc-main.cc--rtl .pm__table-td {
  padding-left: unset;
  padding-right: 1.2em
}

#cc-main.cc--rtl .pm__table-td:before {
  padding-left: 2em;
  padding-right: unset
}

#cc-main.cc--rtl .pm__btn+.pm__btn,
#cc-main.cc--rtl .pm__btn-group+.pm__btn-group {
  margin-left: 0;
  margin-right: .375rem
}

#cc-main.cc--rtl .pm--flip .pm__btn+.pm__btn,
#cc-main.cc--rtl .pm--flip .pm__btn-group+.pm__btn-group {
  margin-left: .375rem;
  margin-right: 0
}

#cc-main.cc--rtl .pm--flip.pm--bar:not(.pm--wide) .pm__btn+.pm__btn,
#cc-main.cc--rtl .pm--flip.pm--bar:not(.pm--wide) .pm__btn-group+.pm__btn-group {
  margin-left: 0
}

@media screen and (max-width:640px) {
  #cc-main .pm {
    border-radius: 0;
    bottom: 0;
    height: auto;
    left: 0;
    max-height: 100%;
    max-width: none !important;
    right: 0;
    top: 0;
    transform: translateY(1.6em)
  }
  #cc-main .pm__body,
  #cc-main .pm__footer,
  #cc-main .pm__header {
    padding: .9em !important
  }
  #cc-main .pm__badge {
    display: none
  }
  #cc-main .pm__section-table,
  #cc-main .pm__table-body,
  #cc-main .pm__table-caption,
  #cc-main .pm__table-td,
  #cc-main .pm__table-th,
  #cc-main .pm__table-tr {
    display: block
  }
  #cc-main .pm__table-head {
    display: none
  }
  #cc-main .pm__table-tr:not(:last-child) {
    border-bottom: 1px solid var(--cc-separator-border-color)
  }
  #cc-main .pm__table-td {
    display: flex;
    justify-content: space-between
  }
  #cc-main .pm__table-td:before {
    color: var(--cc-primary-color);
    content: attr(data-column);
    flex: 1;
    font-weight: 600;
    min-width: 100px;
    overflow: hidden;
    padding-right: 2em;
    text-overflow: ellipsis
  }
  #cc-main .pm__table-td>div {
    flex: 3
  }
  #cc-main .pm__btn-group,
  #cc-main .pm__footer {
    flex-direction: column !important
  }
  #cc-main .pm__btn-group {
    display: flex !important
  }
  #cc-main .pm__btn+.pm__btn,
  #cc-main .pm__btn-group+.pm__btn-group {
    margin: .375rem 0 0 !important
  }
  #cc-main .pm--flip .pm__btn+.pm__btn,
  #cc-main .pm--flip .pm__btn-group+.pm__btn-group {
    margin-bottom: .375rem !important;
    margin-top: 0 !important
  }
  #cc-main .pm--flip .pm__btn-group,
  #cc-main .pm--flip .pm__footer {
    flex-direction: column-reverse !important
  }
  .show--preferences #cc-main .cc--anim .pm {
    transform: translateY(0) !important
  }
}